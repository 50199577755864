import { Button } from "flowbite-react";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { useSearchParams } from "react-router-dom";

type Inputs = {
    senderName: string
    senderEmail: string
    comment:string
  }

const ContactUsFormContainer: React.FC = (props) => {
    
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm<Inputs>()
    const recaptcha = useRef<ReCAPTCHA>(null)
    const [formData, setFormData] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    var projectName = searchParams.get("projectName")

    console.log(projectName)


    const handleInputChange = (event:any) => {
        const { target } = event;
        const { name, value } = target;
        
    
        setFormData({
          ...formData, // Keep existing form data
          [name]: value // Update form data for the input field that changed
        });
      }

    const onSubmit: SubmitHandler<Inputs>= async function (data:any) {
        console.log(data)
        var url = process.env.REACT_APP_ROOT_API!;
        const dataToSubmit = {
            projectName:projectName,
            name:data["senderName"],
            email:data["senderEmail"],
            notes:data["comment"],
          };
        const captchaValue = recaptcha.current?.getValue()
        console.log(recaptcha)
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!' )
        } else {
            // make form submission
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataToSubmit)
            };
            setIsLoading(true);
            const response = await fetch(url, requestOptions);
            setIsLoading(false);
            if(response.ok){
                alert('We received your request and we will get back to you soon.')
            }
        }
    }
    return (
        <>

            <div className="w-full sm:w-9/12 md:w-8/12 lg:w-4/12 justify-between flex flex-col mx-4 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h1 className="font-bold text-lg">Account Deletion Request</h1>
                <form className="mx-auto w-full text-start" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-5">
                        <label htmlFor="senderName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name *</label>
                        <input type="text" id="senderName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" {...register("senderName", { required: true })} onChange={handleInputChange}/>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="senderEmail" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email *</label>
                        <input type="email" id="senderEmail" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@email.com" {...register("senderEmail", { required: true })} onChange={handleInputChange}/>
                    </div>
                    
                    <div className="mb-5">
                        <label htmlFor="comment" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reason for deletion</label>
                        <textarea id="comment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Reason for deletion" {...register("comment")}  onChange={handleInputChange}/>
                    </div>
                    <ReCAPTCHA className="flex justify-center content-center mb-2" ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY || ""} />
                    <Button type="submit" className="w-full" >
                        <h3 className="text-xl font-bold tracking-tight text-white-900">{isLoading? "Loading...":"Submit"}


                        </h3>

                    </Button>
                </form>
            </div>


        </>
    );
}

export default ContactUsFormContainer;