import React from 'react';
import './App.css';
import MainPageLeftContainer from "./components/mainPageLeftContainer/mainPageLeftContainer";

import ContactUsFormContainer from './components/contactUsFormContainer/contactUsFormContainer';

function App() {
  return (
    <div className="App container mx-auto flex flex-col justify-center ">
        
        <div className="flex flex-row container justify-center content-center flex-wrap pt-10">
        
        <ContactUsFormContainer></ContactUsFormContainer>
        </div>
        <br/>
      </div>
    
  );
}

export default App;
